<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box">

          <!--
          <LandPanel title="关于我们" :tabs="aboutServiceTypes" :activeTabName="serviceActiveTabName" more-link="supply" :showMore="false" >
            <template v-slot:tab-lan-service>
              <el-row :gutter="20" v-for="(r, index) in newData" :key="index">
                <el-col :span="12" v-for="(o, index) in r" :key="index">
                  <LandServiceItem :item="o" />
                </el-col>
              </el-row>
            </template>
          </LandPanel>
          -->
            <LandPanel title="中介服务" :tabs="serviceTypes" :activeTabName="serviceActiveTabName" more-link="supply" :showMore="false">
                <template v-slot:tab-lan-service>
                    <el-row :gutter="20" v-for="(r, index) in group1DataNested" :key="index">
                        <el-col :span="12" v-for="(o, index) in r" :key="index">
                            <LandServiceItem :item="o" />
                        </el-col>
                    </el-row>
                </template>
            </LandPanel>
            <el-row :gutter="20">
                <el-col :span="12">
                    <LandPanel title="服务指南" :tabs="[]" activeTabName="single-panel-default" more-link="supply"  :showMore="false">
                        <template v-slot:single-panel-default>
                            <div style="height:280px;">
                                <ul class="list-service-guide">
                                    <li v-for="item in serviceGuide" :key="item.id"><RouterLink target="_blank" :to="`/service-guide?id=`+item.id">{{item.title}}</RouterLink><span>{{formatDate(item.releaseTime)}}</span></li>
                                </ul>
                            </div>
                        </template>
                    </LandPanel>
                </el-col>
                <el-col :span="12">
                    <LandPanel title="办事指南" :tabs="[]" activeTabName="single-panel-default" more-link="supply"  :showMore="false">
                        <template v-slot:single-panel-default>
                            <div style="height:280px;">
                                <ul class="list-do-guide">
                                    <li style="background: #58baee;">
                                        <RouterLink target="_blank" to="/work-guide?id=1666269628714201096"><i class="ti ti-category"></i> 操作手册</RouterLink>
                                    </li>
                                    <li style="background: #9d96f5;" v-if="false">
                                        <RouterLink target="_blank" to="/work-guide?id=1666269628714201097"><i class="ti ti-report-search"></i>信息浏览</RouterLink>
                                    </li>
                                    <li style="background: #84b1f6;">
                                        <RouterLink target="_blank" to="/work-guide?id=1666269628714201098"><i class="ti ti-route"></i>办事流程</RouterLink>
                                    </li>
                                    <li style="background: #67e0e3;">
                                        <RouterLink target="_blank" to="/work-guide?id=1666269628714201099"><i class="ti ti-help-circle"></i>常见问题</RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </LandPanel>
                </el-col>
            </el-row>
        </div>
        <footer2 />
    </div>
</template>

<script>
import LandHeader from '@/components/nav'
import footer2 from "components/footer.vue";
import img_zhuoyuan from "@/assets/service-logo/zhuoyuan.png";
import img_guangda from "@/assets/service-logo/guangda.png";
import img_tudi from "@/assets/service-logo/tudi.png";
import CommonService from '@/service/CommonService';
import dayjs from 'dayjs';
import { RouterLink } from 'vue-router';
import {useNavStore} from "@/stores/nav";

export default {
    name: "ServicesCenter",
    components: {
    LandHeader,
    footer2,
    RouterLink
},
    data() {
        return {
          navStore: useNavStore(),
            serviceGuide:[],
            serviceActiveTabName: "tab-lan-service",
          aboutServiceTypes:[
            {
              name: "tab-lan-service",
              label: "",
            }
          ],
            serviceTypes: [
                {
                    name: "tab-lan-service",
                    label: "土地服务",
                },
                {
                    name: "tab-finance-service",
                    label: "金融服务",
                },
            ],
          serviceItems: [
            {
              image: img_tudi,
              name: "德阳市土地矿权交易中心",
              service: "土地流转/寻找优质客户",
              serviceType: "土地评估",
              contacts: "国土局",
              contactPhone:"0838-2502185",
            }
          ],
            landServiceItems: [
                {
                    image: img_zhuoyuan,
                    name: "四川卓远企业征信咨询有限公司",
                    service: "土地流转/寻找优质客户",
                    serviceType: "土地评估",
                    contacts: "李彦达",
                    contactPhone:"13981082711",
                },
                {
                    image: img_guangda,
                    name: "德阳光大拍卖有限公司",
                    service: "土地流转/寻找优质客户",
                    serviceType: "土地评估",
                    contacts: "徐静",
                    contactPhone:"18281095957",
                },
            ]
        };
    },
    computed: {
        //array to nested array,2 items per row
        group1DataNested() {
            let result = [];
            let row = [];
            for (let i = 0; i < this.landServiceItems.length; i++) {
                row.push(this.landServiceItems[i]);
                if (row.length === 2) {
                    result.push(row);
                    row = [];
                }
            }
            if (row.length > 0) {
                result.push(row);
            }
            return result;
        },
      newData() {
        let result = [];
        let row = [];
        for (let i = 0; i < this.serviceItems.length; i++) {
          row.push(this.serviceItems[i]);
          if (row.length === 2) {
            result.push(row);
            row = [];
          }
        }
        if (row.length > 0) {
          result.push(row);
        }
        return result;
      },
    },
    created() {

    },
    mounted(){
        this.loadServiceGuide();
        this.handerRouter()
    },  
    methods: {
      handerRouter() {
        this.navStore.$patch({
          activeIndex: '4'
        });
        localStorage.setItem('nav_head_index', '4');
        console.log(2222);
      },
        loadServiceGuide(){
            CommonService.getNews({
                type: 4,
                size: 100,
                current: 1,
            }).then(res => {
                if (res.code === 0) {
                    this.serviceGuide = res.data.records;
                }
            })
        },
        formatDate(date) {
            return dayjs(date).format('YYYY-MM-DD')
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs {
    .el-tabs__content {
        .el-row {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

::v-deep .list-service-guide {
    list-style: none;

    li {
        height: 38px;
        line-height: 38px;
        display: flex;
        flex-direction: row;

        &::before {
            content: "\25AA";
            color: #999;
            font-weight: normal;
            display: inline-block;
            width: 2em;
            margin-left: -1em;
            text-align: center;
        }
        a{
            flex: 1;
            color: #333;
        }
        span{
            color: #999;
            font-size: 14px;
        }
    }
}

::v-deep .list-do-guide {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 24px;

    li {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        line-height: 38px;
        display: flex;
        flex-direction: row;

        a{
            flex: 1;
            color: #333;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;

            .ti{
                width: 44px;
                height: 44px;
                font-size: 44px;
                font-weight: normal;
                display: inline-block;
            }
        }
    }
}
</style>
